import * as stats from "./stats";
import {loc2dom, loc2jquery} from "./locations";


const binning_policies = {
    // https://www.statisticshowto.com/choose-bin-sizes-statistics/

    /**
     * Sturge's rule for calculating the number of bins:
     *
     *     K = 1 + ceil(log2(n))
     *
     * @param values
     * @param min
     * @param max
     * @returns {{[p: string]: integer}}
     */
    sturges(values, [min, max]) {
        const [low, hi] = stats.range(min, max, values);
        let bincount = Math.ceil(Math.log2(values.length)) + 1;
        const binsize = Math.round((hi - low) / bincount);
        bincount -= Math.floor(((bincount * binsize) - hi) / binsize);
        // console.log("sturges:BINCOUNT:", bincount, "BIN:SIZE:", binsize, "DELTA:", checkbins(bincount, binsize, hi));
        return stats.count_buckets(values, bincount, binsize, low, hi);
    },

    /**
     * Used by excel...
     *
     * @param values
     * @param min
     * @param max
     */
    sqrt(values, [min, max]) {
        const [low, hi] = stats.range(min, max, values);
        let bincount = Math.ceil(Math.sqrt(values.length));
        const binsize = Math.round((hi - low) / bincount);
        bincount -= Math.floor(((bincount * binsize) - hi) / binsize);
        // console.log("sqrt:BINCOUNT:", bincount, "BIN:SIZE:", binsize, "DELTA:", checkbins(bincount, binsize, hi));
        return stats.count_buckets(values, bincount, binsize, low, hi);
    },

    scott(values, [min, max]) {
        const [low, hi] = stats.range(min, max, values);
        const binsize = Math.ceil((3.49 * stats.stddev(values)) / Math.cbrt(values.length));
        // let bincount = Math.ceil((3.49 * stats.stddev(values)) / Math.cbrt(values.length));
        // const binsize = Math.round((hi - low) / bincount);
        let bincount = Math.ceil((hi - low) / binsize);
        bincount -= Math.floor(((bincount * binsize) - hi) / binsize);
        // console.log("scott:BINCOUNT:", bincount, "BIN:SIZE:", binsize, "DELTA:", checkbins(bincount, binsize, hi));
        return stats.count_buckets(values, bincount, binsize, low, hi);
    },

    /**
     * Rice's Rule:
     *
     *   cube-root(values.length) * 2
     *
     * @param values
     * @param min
     * @param max
     * @returns {*}
     */
    rice(values, [min, max]) {
        const [low, hi] = stats.range(min, max, values);
        let bincount = 2 * Math.ceil(Math.cbrt(values.length));
        const binsize = Math.round((hi - low) / bincount);
        bincount -= Math.floor(((bincount * binsize) - hi) / binsize);
        return stats.count_buckets(values, bincount, binsize, low, hi);
    },

    freedman_diaconis(values, [min, max]) {
        const [low, hi] = stats.range(min, max, values);
        const binsize = Math.ceil(2 * IQR(values) / Math.cbrt(values.length));
        let bincount = Math.ceil((hi - low) / binsize);
        bincount -= Math.floor(((bincount * binsize) - hi) / binsize);
        // console.log("freedman_diaconis:BINCOUNT:", bincount, "BIN:SIZE:", binsize, "DELTA:", checkbins(bincount, binsize, hi));
        return stats.count_buckets(values, bincount, binsize, low, hi);
    },

    /**
     * Fixed bucket size.
     */
    binsize(values, [min, max], binsize) {
        const [low, hi] = stats.range(min, max, values);
        let bincount = binsize === 1 ? ((hi - low) + 1) : Math.ceil((hi - low) / binsize);
        // let bincount = Math.ceil((hi - low) / binsize);
        bincount -= Math.floor(((bincount * binsize) - hi) / binsize);
        // console.log("binsize:BINCOUNT:", bincount, "BIN:SIZE:", options.bin_size, "DELTA:", checkbins(bincount, binsize, hi));
        return stats.count_buckets(values, bincount, binsize, low, hi);
    }
};


/**
 * Display values as percentages in a pie chart.
 * Usage::
 *
 <div id="my-pie"></div>
 <script>
 dkcharts.Histogram('#my-histogram', {
            size: 500,                          // size of the chart
            // width: 800,                      // either size or width + height
            // height: 200,

            buckets: {
                ["0-5"]: 130,
                ["5-10"]: 50,
                ...
            }
        });
 </script>
 .

 * @param {string} location - selector to existing DOM element
 * @param {*} options
 */
export class Histogram {
    constructor(location, options) {
        // const loc = document.querySelector(location);
        const loc = loc2dom(location);
        const jqloc = loc2jquery(location);
        const width = options.width || options.size || 1000;
        const height = options.height || options.size || 1000;
        const size = options.size || 1000;

        loc.style.width = `${width}px`;
        loc.style.height = `${height}px`;
        loc.classList.add('dkcharts', 'dkcharts-histogram');

        const font_size = height > 100 ? height * 0.35 : height * 0.30;

        // range is the min/max of the x axis (used to calculate bucket size)
        this.range = options.range || [null, null];

        let buckets = options.buckets || {};
        this.bin_size = null;
        this.bin_policy = 'sturges';
        if (options.bin_size) {
            this.bin_policy = 'binsize';
            this.bin_size = options.bin_size;
        }
        if (options.bin_policy) this.bin_policy = options.bin_policy;

        if (options.values && !options.buckets) {
            buckets = binning_policies[this.bin_policy](options.values, this.range, this.bin_size);
        }

        // console.log("BUCKETS:", buckets);
        // console.log("BUCKET:SUM:", Object.values(buckets).reduce((a, b) => a + b));
        const params = {
            chart: {
                type: 'column',
                styledMode: true,
            },
            title: options.title,
            subtitle: options.subtitle,
            exporting: {enabled: options.enable_export || false},
            // tooltip: {enabled: false},
            credits: {enabled: false},
            // plotOptions: {},

            xAxis: {
                categories: Object.keys(buckets),
                crosshair: true,
                title: {
                    text: options.xlabel,
                },
                min: options.xmin || null
            },
            yAxis: {
                title: {
                    text: options.ylabel,
                },
                max: options.ymax || null,
            },
            tooltip: {
                formatter: function() {
                    return `<tr><td style="color:{series.color};padding:0; text-decoration: underline">${this.series.name}</td><br>
                            <tr><td style="color:{series.color};padding:0">${options.xlabel}:</td>
                            <td style="padding:0"><b>${this.x}</b></td></tr> <br>
                            <tr><td style="color:{series.color};padding:0">${options.ylabel}:</td>
                            <td style="padding:0"><b>${this.y}</b></td></tr> <br>
                            `;
                },
                useHTML: true,
                // headerFormat: `<tr><td style="color:{series.color};padding:0">${options.value_title}</td><br>`,
                // pointFormat: `<tr><td style="color:{series.color};padding:0">${options.xlabel}:</td>
                //               <td style="padding:0"><b>{point.x}</b></td></tr> <br>
                //               <tr><td style="color:{series.color};padding:0">${options.ylabel}: </td>
                //               <td style="padding:0"><b>{point.y}</b></td></tr>`,
                // footerFormat: '</table>',
                // shared: true,
                // useHTML: true
            },
            plotOptions: {
                column: {
                    pointPadding: 0.2,
                    borderWidth: 0
                }
            },
            series: [{
                name: options.value_title,
                data: Object.values(buckets),
            }]
        };

        this.chart = Highcharts.chart(loc, params);
    }

    data2buckets(vals) {
        return binning_policies[this.bin_policy](vals, this.range, this.bin_size);
    }

    set values(vals) {
        if (this.bin_policy !== 'binsize') {
            console.warn(`
            
                you should set the 'bin_size' parameter when re-assigning values,
                since we do not want to change the x-axis grouping.
                
            `);
        }
        const buckets = this.data2buckets(vals);
        this.chart.series[0].setData(Object.values(buckets));
    }

    set ymax(val) {
        this.chart.yAxis[0].update({max: val});
    }

    // set setBuckets(vals) {
    //     this.chart.xAxis[0].setCategories(Object.keys(vals));
    //     this.chart.series[0].setData(Object.values(vals));
    // }
}
