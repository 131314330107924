/**
 * Display values as percentages in a pie chart.
 * Usage::
 *
    <div id="my-pie"></div>
    <script>
        dkcharts.PercentPie('#my-pie', {
            size: 500,                          // size of the chart
            value_title: "bestått",
            selected_value: 'bestått',
            values: {
                bestått: 130,
                ["ikke bestått"]: 50,
            }
        });
    </script>

 * @param {*} location
 * @param {*} options
 */
import {loc2dom, loc2jquery} from "./locations";

export class PercentPie {

    constructor(location, options) {
        const loc = loc2dom(location);
        const jqloc = loc2jquery(location);
        const size = options.size || 1000;
        const font_size = size > 100 ? size * 0.35 : size * 0.30;

        loc.style.width = `${size}px`;
        loc.style.height = `${size}px`;
        loc.classList.add('dkcharts', 'dkcharts-pie');

        const data = Object.entries(options.values).map(([k, v]) => {
            const res = {name: k, y: v};
            if (k === options.selected_value) {
                res.selected = true;
                res.sliced = true;
            }
            return res;
        });

        const params = {
            chart: {
                // plotBackgroundColor: null,
                // plotBorderWidth: null,
                // plotShadow: false,
                type: 'pie',
                styledMode: true,
            },
            title: {
                text: null
            },
            tooltip: {
                pointFormat: '{point.name}: <b>{point.y}</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            exporting: {enabled: options.enable_export || false},
            credits: {enabled: false},

            plotOptions: {
                pie: {
                    allowPointSelect: false,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        style: {
                            fontSize: '10px',
                            fontFamily: 'Verdana',
                            fontWeight: 'normal',
                        }
                    }
                }
            },
            series: [{
                name: options.value_title || "value_title",
                colorByPoint: true,
                data: data,
            }]
        };

        this.chart = Highcharts.chart(loc, params);
    }
}


export class PercentPieLegend {

    constructor(location, options) {
        const loc = loc2dom(location);
        const jqloc = loc2jquery(location);
        const size = options.size || 1000;
        const font_size = size > 100 ? size * 0.35 : size * 0.30;

        loc.style.width = `${size}px`;
        loc.style.height = `${size}px`;
        loc.classList.add('dkcharts', 'dkcharts-pie');

        const data = Object.entries(options.values).map(([k, v]) => {
            const res = {name: k, y: v};
            if (k === options.selected_value) {
                res.selected = true;
                res.sliced = true;
            }
            return res;
        });

        const params = {
            chart: {
                type: 'pie',
            },
            title: {
                text: null
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            accessibility: {
                point: {
                    valueSuffix: '%'
                }
            },
            exporting: {enabled: options.enable_export || false},
            credits: {enabled: false},
            legend: {
                useHTML: true,
                labelFormatter: function() {
                    // Return the original value along with the name
                    return this.name + ': ' + this.y;
                }
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '{point.name}: {point.percentage:.1f} %'
                    },
                    showInLegend: true
                }
            },
            series: [{
                name: options.value_title || "value_title",
                colorByPoint: true,
                data: data,
            }]
        };

        this.chart = Highcharts.chart(loc, params);
    }
}
